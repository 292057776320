// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import AWS from 'aws-sdk'

// services
import { store } from 'services/state'
import { updateAllUserData } from 'services/api-catalog'
import { initApiGatewayClient, apiGatewayClient, cognitoDomain, cognitoIdentityPoolId, cognitoUserPoolId, cognitoClientId, cognitoRegion, IdentityId } from 'services/api'
import * as jwt_decode from "jwt-decode";

export function isAuthenticated() {
  return store.idToken
}

export function isAdmin() {
  return store.idToken &&
  `${jwt_decode(store.idToken)['cognito:preferred_role']}`.includes('-CognitoAdminRole-')
}

export function init() {
  initApiGatewayClient() // init a blank client (will get overwritten if we have creds)

  // attempt to refresh credentials from active session

  let idToken
  let identityId
  let parsedToken
  let tokenType
  let valid = false

  try {
    idToken = localStorage.getItem(cognitoUserPoolId)
    identityId = localStorage.getItem(IdentityId)
    tokenType = localStorage.getItem('TokenType')

    if(!tokenType) {
      tokenType = 'OpenId'
    }

    if (idToken) { // this `if` prevents console.error spam
      parsedToken = jwt_decode(idToken)
      valid = parsedToken.exp * 1000 > new Date()
    }
  } catch (error) {
    console.error(error)
  }

  if (valid) {
    store.idToken = idToken
    store.identityId = identityId
    store.tokenType = tokenType

    if(tokenType === 'Bearer'){
      setCredentials()
    } else {
      setCredentialsFromOpenIdToken()
    }

  } else {
    logout()
  }
}

export function login() {
  return new Promise((resolve, reject) => {
    let idToken, accessToken, username, identityId, tokenType

    try {

      window.location.hash
        .replace(/^#/, '')
        .split('&')
        .map(param => param.split('='))
        .forEach(param => {
          // record the id_token and access_token
          if (param[0] === 'id_token') idToken = param[1]
          if (param[0] === 'access_token') accessToken = param[1]
          if (param[0] === 'IdentityId') identityId = param[1]
          if (param[0] === 'token_type') tokenType = param[1]
        })

      if (idToken) {

        // we get both, we set both, but we only really care about the idToken

        if(!tokenType) {
          tokenType = 'OpenId'
        }


        localStorage.setItem(cognitoUserPoolId, idToken)
        localStorage.setItem(IdentityId, identityId)
        localStorage.setItem('TokenType', tokenType)

        store.idToken = idToken
        store.identityId = identityId;
        store.tokenType = tokenType;

        if(tokenType === 'Bearer'){

          username = jwt_decode(idToken)['cognito:username']

          setCredentials()
        } else {
          setCredentialsFromOpenIdToken()
        }

        resolve(idToken)
      }
    } catch (error) {
      reject(error)
    }
  })
}

function setCredentialsFromOpenIdToken() {
  var PoolID = "us-west-2:a341d5dc-fd98-4bc1-a167-72418b9a1c82"

  var IDENTITY_PARAMS =
  {
    IdentityPoolId: PoolID,
    RoleSessionName: "web",
  };

  var params = IDENTITY_PARAMS;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: PoolID,
    IdentityId: store.identityId,
    Logins: {
      'cognito-identity.amazonaws.com': store.idToken
    }
  });

  AWS.config.credentials.get(function (err, data) {
    if (err)
      console.log(err, err.stack); // an error occurred
    else {
      //Access AWS resources here
      console.log("Access AWS resources here", data)
      console.log(AWS.config.credentials)

      initApiGatewayClient(AWS.config.credentials)

      updateAllUserData()

      return apiGatewayClient()
          .then(apiGatewayClient => apiGatewayClient.post('/signin', {}, {}, {}))
    }
  });
}


function setCredentials() {
  let jwt_token = jwt_decode(store.idToken);
  let userId = 'EMPTY';

  if(jwt_token.identities && jwt_token.identities.length && jwt_token.identities[0].userid ){
   userId = jwt_token.identities[0].userId;
  }

  console.log('COGNITO JWT TOKEN',jwt_token, userId);

  let preferred_role = jwt_decode(store.idToken)['cognito:preferred_role']
  let params = {
    IdentityPoolId: cognitoIdentityPoolId,
    Logins: {
      [`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`]: store.idToken
    }
  }

  if (preferred_role)
    params.RoleArn = preferred_role

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params)

  return new Promise((resolve, reject) => {
    AWS.config.credentials.refresh((error) => {
      if (error) {
        console.error(error)
        return reject(error)
      }

      initApiGatewayClient(AWS.config.credentials)
      updateAllUserData()
  
      return apiGatewayClient()
        .then(apiGatewayClient => apiGatewayClient.post('/signin', {}, {}, {}))
    })
  })
}

export function logout() {
  if (store.idToken) {
    store.resetUserData()
    localStorage.clear()

    if (cognitoDomain) {
      // redirect to cognito to log out there, too
      window.location = `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${window.location.protocol}//${window.location.host}`;
    }
  }
}
