// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// react-router
import { Link } from 'react-router-dom'

// semantic-ui
import { Header, Segment, Container, Image, Button } from 'semantic-ui-react'

export const HomePage = observer(() => (
  <React.Fragment>
    <Segment className={'ptv-hero'} vertical textAlign='center' style={{ color: "whitesmoke", backgroundColor: "slategray", padding: "40px 0px", margin: "0px !important" }}>
      <Image centered size='small' src='/custom-content/home-image.png' />
      <Header as='h1' style={{ color: "whitesmoke" }}>{fragments.Home.header}</Header>
      <p style={{ fontSize: "1.2em"}}>{fragments.Home.tagline}</p>
      <p style={{ fontSize: "1.2em"}}>{fragments.Home.tagline2}</p>
      {/*<Link to="/getting-started"><Button positive>{fragments.Home.gettingStartedButton}</Button></Link>*/}
        <Link to="/apis" style={{ padding: "0.78571429em 1.5em 0.78571429em", color: "white" }}><Button positive>{fragments.Home.apiListButton}</Button></Link>
    </Segment>
    <Segment vertical style={{ padding: "40px 0px", margin: "0 !important" }}>
      <Container fluid text textAlign='justified'>
        <fragments.Home.jsx />
      </Container>
    </Segment>
  </React.Fragment>
))

export default HomePage
